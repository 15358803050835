import {
  BREADCRUM,
  LEAD_PAGE_SECTION_TYPE,
  ROUTES_PATH,
} from "helpers/constants/constants.helper"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Redirect, useParams } from "react-router-dom"
import {
  setBreadcrumbItems,
  addTextSection,
  addVideoSection,
  getSingleSection,
  setCurrentLanguageCode,
  updateTextSection,
  updateVideoSection,
} from "store/actions"
import TextSection from "./TextSection"
import VideoSection from "./VideoSection"

const AddSectionPage = ({
  setBreadcrumbItems,
  addTextSection,
  currentLanguageCode,
  leadPageLoader,
  addVideoSection,
  getSingleSection,
  setCurrentLanguageCode,
  updateTextSection,
  updateVideoSection,
}) => {
  const params = useParams()

  useEffect(() => {
    if (params.parentId && params.childId) {
      const updateBreadcrumItems = [
        {
          title: `${params.product} Section`,
          link: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}/${params.product}`,
        },
        { title: `${params.sectionType}`, link: "/" },
      ]
      setBreadcrumbItems(
        {
          title: BREADCRUM.LEAD_PAGE,
          pageTitleLink: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}`,
        },
        updateBreadcrumItems
      )
    } else {
      const breadcrumbItems = [
        {
          title: `${params.product} Section`,
          link: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}/${params.product}`,
        },
        { title: `${params.sectionType}`, link: "/" },
      ]
      setBreadcrumbItems(
        {
          title: BREADCRUM.LEAD_PAGE,
          pageTitleLink: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}`,
        },
        breadcrumbItems
      )
    }
  }, [])

  return (
    <>
      {params?.sectionType.includes(LEAD_PAGE_SECTION_TYPE.VIDEO) ? (
        <VideoSection
          currentLanguageCode={currentLanguageCode}
          addVideoSection={addVideoSection}
          leadPageLoader={leadPageLoader}
          getSingleSection={getSingleSection}
          setBreadcrumbItems={setBreadcrumbItems}
          setCurrentLanguageCode={setCurrentLanguageCode}
          updateVideoSection={updateVideoSection}
        />
      ) : params?.sectionType.includes(LEAD_PAGE_SECTION_TYPE.Text) ? (
        <TextSection
          addTextSection={addTextSection}
          currentLanguageCode={currentLanguageCode}
          leadPageLoader={leadPageLoader}
          getSingleSection={getSingleSection}
          setCurrentLanguageCode={setCurrentLanguageCode}
          updateTextSection={updateTextSection}
        />
      ) : (
        <Redirect to={`/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}`} />
      )}
    </>
  )
}

const mapStatetoProps = state => {
  const { Language, LeadPages } = state
  // console.log(state, "s")
  const { loading: leadPageLoader } = LeadPages
  const { currentLanguageCode } = Language
  return { currentLanguageCode, leadPageLoader }
}

export default connect(mapStatetoProps, {
  setBreadcrumbItems,
  addTextSection,
  addVideoSection,
  getSingleSection,
  setCurrentLanguageCode,
  updateTextSection,
  updateVideoSection,
})(AddSectionPage)
