import TabPill from "components/common/tab-pill"
import {
  LEAD_PAGE_SECTION_TYPE,
  ROUTES_PATH,
} from "helpers/constants/constants.helper"
import React, { useEffect, useState } from "react"
import { FaRegEdit } from "react-icons/fa"
import { MdDelete, MdClose, MdCheck } from "react-icons/md"
import { useHistory, useParams } from "react-router-dom"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Table,
  UncontrolledTooltip,
} from "reactstrap"
import { IoChevronDown } from "react-icons/io5"
const sectionTableTableHead = [
  { value: "Section Title", id: "id", classNames: "text-start" },
  { value: "Language", id: "language" },
  { value: "Status", id: "status" },
  { value: "Action", id: "action" },
]

const LeadPageSettingModal = props => {
  const {
    modalHeaderText,
    modalShow,
    setModalShow,
    currentSelectedLeadSection,
    updateSectionStatus,
    languages,
    modalDeleteAction,
  } = props

  const [singlebtn, setSinglebtn] = useState(false)
  const [selectedChild, setSelectedChild] = useState(null)
  const history = useHistory()
  const params = useParams()
  const remainingLanguageArray = languages.filter(language => {
    return !currentSelectedLeadSection.LanguagesLeadPages.some(
      langObj => langObj.language_code === language.language_code
    )
  })
  useEffect(() => {
    if (!currentSelectedLeadSection) setModalShow(false)
  }, [])

  return (
    <Modal isOpen={modalShow} centered={true} size="lg">
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {/* Are you sure you want to Logout? */}
          {modalHeaderText}
        </h5>
        <button
          type="button"
          onClick={() => {
            setModalShow(false)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {currentSelectedLeadSection ? (
          <>
            <div className="info-container">
              <div className="row">
                <div className="col-6">
                  {/* <div className="row mb-1">
                    <div className="col-6">Parent Language:</div>
                    <div className="col-4">
                      <TabPill
                        classNames="mb-0 w-100"
                        label="Hindi"
                        status={true}
                      />
                    </div>
                  </div> */}
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="w-50"></div>
                    <div className="w-50">
                      {remainingLanguageArray?.length ? (
                        <Dropdown
                          isOpen={singlebtn}
                          toggle={() => setSinglebtn(!singlebtn)}
                          className=""
                        >
                          <DropdownToggle
                            className="btn btn-dark w-100 mb-2"
                            caret
                          >
                            Add Language <IoChevronDown />
                          </DropdownToggle>
                          <DropdownMenu className="w-100">
                            {remainingLanguageArray.map((languageObj, idx) => {
                              return (
                                <DropdownItem
                                  key={idx}
                                  onClick={() => {
                                    history.push(
                                      `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}/${params.product}/${currentSelectedLeadSection.master_sections.title}/${languageObj.language_code}/${currentSelectedLeadSection.id}`
                                    )
                                  }}
                                >
                                  {languageObj?.name}
                                </DropdownItem>
                              )
                            })}
                          </DropdownMenu>
                        </Dropdown>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-container">
              <div className="table-responsive">
                <Table className="table-striped mb-0">
                  <thead className="bg-dark text-white sticky">
                    <tr className="text-center">
                      {sectionTableTableHead.map((item, index) => {
                        return (
                          <th key={index} className={item.classNames || null}>
                            {item.value}
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody className="table-striped ">
                    {currentSelectedLeadSection?.LanguagesLeadPages?.length ? (
                      currentSelectedLeadSection.LanguagesLeadPages.filter(
                        item => item.section_title
                      ).map((item, index) => {
                        return (
                          <tr key={index} className="position-relative">
                            <td className="text-start">
                              {item?.section_title}
                            </td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <TabPill
                                  classNames="mb-0"
                                  label={item?.language_code}
                                  status={item?.status}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center">
                                <div className="form-check form-switch form-switch-md">
                                  <CheckBox
                                    status={item.status}
                                    item={item}
                                    currentSelectedLeadSection={
                                      currentSelectedLeadSection
                                    }
                                    updateSectionStatus={updateSectionStatus}
                                    section_type={
                                      currentSelectedLeadSection
                                        ?.master_sections?.title
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                            <td
                              className="icon-container"
                              style={{ maxWidth: "40px" }}
                            >
                              <IconContainer
                                sectionTitle={
                                  currentSelectedLeadSection?.master_sections
                                    ?.title || ""
                                }
                                product={params?.product || ""}
                                childId={item.id}
                                history={history}
                                setModalShow={setModalShow}
                                modalDeleteAction={modalDeleteAction}
                                selectedChild={selectedChild}
                                setSelectedChild={setSelectedChild}
                              />
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={4}>
                          <h2 className="text-center">
                            No data available into the FAQ list
                          </h2>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </Modal>
  )
}

const CheckBox = ({ item, status, updateSectionStatus, section_type }) => {
  const [isChecked, setIsChecked] = useState(status || false)
  const [isDisabled, setIsDisabled] = useState(false)
  const checkHandler = () => {
    setIsChecked(!isChecked)
    setIsDisabled(false)
  }
  return (
    <>
      <input
        type="checkbox"
        className="form-check-input cursor-pointer"
        id="customSwitchsizemd"
        checked={isChecked}
        disabled={isDisabled}
        onChange={() => {
          setIsDisabled(true)
          updateSectionStatus(
            {
              ...item,
              id: item?.id,
              status: !item.status,
            },
            checkHandler,
            section_type
          )
        }}
      />
    </>
  )
}

const IconContainer = ({
  sectionTitle,
  product,
  childId,
  history,
  setModalShow,
  modalDeleteAction,
  selectedChild,
  setSelectedChild,
}) => {
  return (
    <div className={`justify-content-center align-items-center }`}>
      {childId === selectedChild ? (
        <>
          <MdCheck
            className="text-info icons text-danger"
            id={`check${childId}`}
            onClick={() => modalDeleteAction(childId, setModalShow)}
          />
          <MdClose
            className="text-secondary icons"
            id={`close${childId}`}
            onClick={() => setSelectedChild(null)}
          />
          {/* unexpected Error Occured */}
          {/* <UncontrolledTooltip
            target={`check${childId}`}
          >
            Yes
          </UncontrolledTooltip> */}
          <UncontrolledTooltip target={`close${childId}`}>
            Cancel
          </UncontrolledTooltip>
        </>
      ) : (
        <>
          <FaRegEdit
            className="text-success icons"
            id={`edit${childId}`}
            onClick={() => {
              if (sectionTitle.includes(LEAD_PAGE_SECTION_TYPE.VIDEO)) {
                history.push(
                  `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}/${ROUTES_PATH.EDIT_LEAD_PAGE}/${product}/${LEAD_PAGE_SECTION_TYPE.VIDEO} Section/${childId}`
                )
              } else {
                history.push(
                  `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}/${ROUTES_PATH.EDIT_LEAD_PAGE}/${product}/${LEAD_PAGE_SECTION_TYPE.Text} Section/${childId}`
                )
              }
            }}
          />
          <UncontrolledTooltip target={`edit${childId}`}>
            Edit
          </UncontrolledTooltip>
          <MdDelete
            className="text-danger icons"
            id={`delete${childId}`}
            onClick={() => {
              setSelectedChild(childId)
              // setShowDeleteModal(true)
              // setCurrentSectionId(value.id)
            }}
          />
          <UncontrolledTooltip target={`delete${childId}`}>
            Delete
          </UncontrolledTooltip>
        </>
      )}
    </div>
  )
}
export default LeadPageSettingModal
