import SkeletonLoader from "components/common/SkeletonLoader"
import TabPill from "components/common/tab-pill"
import ConfirmationModal from "components/modal/ConfirmationModal"
import {
  BREADCRUM,
  LEAD_PAGE_SECTION_TYPE,
  ROUTES_PATH,
} from "helpers/constants/constants.helper"
import { getRemainingLanguages } from "helpers/utils.helper"
import React, { useEffect } from "react"
import { useState } from "react"
import { MdDelete, MdSettings } from "react-icons/md"
import { connect } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { IoChevronDown } from "react-icons/io5"
import {
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledTooltip,
} from "reactstrap"
import {
  setBreadcrumbItems,
  getAllLeadPageSection,
  deleteLeadPageSection,
  updateVideoSection,
  updateTextSection,
} from "store/actions"
import LeadPageSettingModal from "./LeadPageSettingModal"

const SectionListpage = ({
  setBreadcrumbItems,
  getAllLeadPageSection,
  LeadPageAllSections,
  deleteLeadPageSection,
  leadPageLoading,
  updateVideoSection,
  updateTextSection,
  languages,
}) => {
  const params = useParams()
  const history = useHistory()
  const [singlebtn, setSinglebtn] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [currentSectionId, setCurrentSectionId] = useState(null)
  const [currentSelectedLeadSection, setcurrentSelectedLeadSection] = useState(
    []
  )
  const [showSettingModal, setShowSettingModal] = useState(false)

  const modalDeleteAction = (id, closeModal) => {
    const callback = () => {
      getAllLeadPageSection({
        product_type: params.product,
        pageNum: 0,
        recordLimit: 10,
      })
      if (currentSelectedLeadSection.LanguagesLeadPages.length > 1) {
        setcurrentSelectedLeadSection({
          ...currentSelectedLeadSection,
          LanguagesLeadPages:
            currentSelectedLeadSection.LanguagesLeadPages.filter(
              item => item.id !== id
            ),
        })
      } else {
        closeModal(false)
      }
    }
    deleteLeadPageSection(`child/${id}`, callback)
  }

  const updateSectionStatus = (params, cb, section_type) => {   
    function changeStatusInList() {
      if (LeadPageAllSections && LeadPageAllSections.length) {
        setcurrentSelectedLeadSection({
          ...currentSelectedLeadSection,
          LanguagesLeadPages: currentSelectedLeadSection.LanguagesLeadPages.map(
            leadPage => {
             return leadPage.id === params.id ? params :leadPage  
            }
          ),
        })
      }
    }

    if (section_type.includes(LEAD_PAGE_SECTION_TYPE.Text)) {
      updateTextSection({ id: params.id, status: params.status }, () => {
        cb()
        changeStatusInList()
      })
    } else if (section_type.includes(LEAD_PAGE_SECTION_TYPE.VIDEO)) {
      updateVideoSection({ id: params.id, status: params.status }, () => {
        cb()
        changeStatusInList()
      })
    }
  }

  useEffect(() => {
    setBreadcrumbItems(
      {
        title: BREADCRUM.LEAD_PAGE,
        pageTitleLink: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}`,
      },
      [{ title: `${params.product} Section`, link: "/" }]
    )
    getAllLeadPageSection({
      product_type: params.product,
      pageNum: 0,
      recordLimit: 10,
    })
  }, [])

  return (
    <>
      <ConfirmationModal
        confirmBtnText="Delete"
        modalHeaderText="You are about to delete this section. This content will be removed from website, Are you sure?"
        confirmAction={() => {
          deleteLeadPageSection(currentSectionId, () => {
            getAllLeadPageSection({
              product_type: params.product,
              pageNum: 0,
              recordLimit: 10,
            })
            setShowDeleteModal(false)
          })
        }}
        modalShow={showDeleteModal}
        setModalShow={setShowDeleteModal}
        loading={leadPageLoading}
      />
      {showSettingModal && (
        <LeadPageSettingModal
          modalHeaderText={`${params?.product || "Leads"} Section`}
          modalShow={showSettingModal}
          setModalShow={setShowSettingModal}
          currentSelectedLeadSection={currentSelectedLeadSection}
          updateSectionStatus={updateSectionStatus}
          languages={languages}
          modalDeleteAction={modalDeleteAction}
        />
      )}
      <Card>
        <CardBody>
          <div className="row mb-4">
            <div className="col-9"></div>
            <div className="col-3">
              <Dropdown
                isOpen={singlebtn}
                toggle={() => setSinglebtn(!singlebtn)}
                className=""
              >
                <DropdownToggle className="btn btn-dark w-100" caret>
                  Create section <IoChevronDown />
                </DropdownToggle>
                <DropdownMenu className="w-100">
                  <DropdownItem
                    onClick={() => {
                      history.push(
                        `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}/${params?.product}/${LEAD_PAGE_SECTION_TYPE.Text} Section`
                      )
                    }}
                  >
                    Text section
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      history.push(
                        `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}/${params?.product}/${LEAD_PAGE_SECTION_TYPE.VIDEO} Section`
                      )
                    }}
                  >
                    Video section
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <Table className="table-striped mb-0 text-center">
            <thead className="bg-dark text-white sticky">
              <tr>
                <th>Title</th>
                <th style={{ width: "160px" }}>Type</th>
                <th style={{ width: "400px" }}>Languages</th>
                <th style={{ width: "123px" }}>Actions</th>
              </tr>
            </thead>
            <tbody className="table-striped break-word">
              {leadPageLoading ? (
                <tr>
                  <td>
                    <SkeletonLoader />
                  </td>
                  <td>
                    <SkeletonLoader />
                  </td>
                  <td>
                    <SkeletonLoader />
                  </td>
                  <td>
                    <SkeletonLoader />
                  </td>
                </tr>
              ) : LeadPageAllSections.length ? (
                LeadPageAllSections.map((value, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td>{value?.label || "-"}</td>
                        <td>{value?.master_sections?.title || "-"}</td>
                        <td>
                          <LanguageTabs
                            languageArray={value?.LanguagesLeadPages || []}
                            sectionType={value.master_sections.title}
                            sectionId={value.id}
                            languages={languages}
                            parentSectionId={value.id}
                          />
                        </td>
                        <td
                          className="icon-container"
                          style={{ maxWidth: "40px" }}
                        >
                          <div className="justify-content-center">
                            <MdSettings
                              className="text-success icons"
                              id={`settingTooltip${index}`}
                              onClick={() => {
                                setcurrentSelectedLeadSection(value)
                                setShowSettingModal(true)
                              }}
                            />
                            <UncontrolledTooltip
                              target={`settingTooltip${index}`}
                            >
                              Settings
                            </UncontrolledTooltip>
                            <MdDelete
                              className="text-danger icons"
                              id={`deleteTooltip${index}`}
                              onClick={() => {
                                setShowDeleteModal(true)
                                setCurrentSectionId(value.id)
                              }}
                            />
                            <UncontrolledTooltip
                              target={`deleteTooltip${index}`}
                            >
                              Delete
                            </UncontrolledTooltip>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={200}>
                    <h3>No Section Found</h3>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  )
}

const LanguageTabs = ({
  languageArray,
  sectionType,
  languages,
  parentSectionId,
}) => {
  const params = useParams()
  const history = useHistory()
  const remainingLanguages = getRemainingLanguages(languages, languageArray)
  return (
    <div>
      {languageArray.map((languageObj, i) => (
        <TabPill
          key={i}
          status={languageObj.status}
          label={languageObj.language_code}
          action={() => {}}
          classNames="mb-0 lang-tab"
        />
      ))}
      {remainingLanguages.map((language, index) => (
        <TabPill
          key={index}
          label={`${language.language_code}+`}
          action={() =>
            history.push(
              `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}/${params.product}/${sectionType}/${language.language_code}/${parentSectionId}`
            )
          }
          classNames="mb-0 cursor-pointer lang-tab add_language_badge"
        />
      ))}
    </div>
  )
}

const mapStatetoProps = state => {
  const {
    LeadPageAllSections,
    table_loader: leadPageLoading,
    metadata,
  } = state.LeadPages
  const { languages } = state.Language
  return { LeadPageAllSections, leadPageLoading, metadata, languages }
}

export default connect(mapStatetoProps, {
  setBreadcrumbItems,
  getAllLeadPageSection,
  deleteLeadPageSection,
  updateVideoSection,
  updateTextSection,
})(SectionListpage)
