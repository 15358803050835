import { BREADCRUM, ROUTES_PATH } from "helpers/constants/constants.helper"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { setBreadcrumbItems } from "store/actions"
import styles from "./lead.module.css"
import healthInsuranceIcon from "assets/svg/healthInsuranceIcon.svg"
import travelInsuranceIcon from "assets/images/product/travel.png"
import bikeInsuranceIcon from "assets/images/product/bikeInsuranceIcon.png"
import commercialInsuranceIcon from "assets/images/product/commercial.png"
import otherInsuranceIcon from "assets/images/product/other.png"
import carInsuranceIcon from "assets/svg/carInsuranceIcon.svg"

const products = [
  {
    id: "Health",
    image: healthInsuranceIcon,
    name: "Health",
    status: true,
  },
  {
    id: "Bike",
    image: bikeInsuranceIcon,
    name: "Two Wheeler",
    status: true,
  },
  {
    id: "Car",
    image: carInsuranceIcon,
    name: "Car",
    status: true,
  },
  {
    id: "Commercial",
    image: commercialInsuranceIcon,
    name: "Commercial",
    status: false,
  },
  {
    id: "Travel",
    image: travelInsuranceIcon,
    name: "Travel",
    status: false,
  },
  {
    id: "Others",
    image: otherInsuranceIcon,
    name: "Others",
    status: false,
  },
]

const LeadPage = ({ setBreadcrumbItems }) => {
  const history = useHistory()
  useEffect(() => {
    setBreadcrumbItems(BREADCRUM.LEAD_PAGE)
  }, [])

  return (
    <>
      <div className={`${styles.category_section} w-100 position-relative`}>
        <div className="container">
          <div
            className={`${styles.categotry_cards_container} d-flex flex-column flex-wrap flex-sm-row text-center`}
          >
            {products?.length
              ? products.map(product => {
                  return (
                    <div
                      key={product.id}
                      className={`${
                        styles.categotry_card
                      } d-flex flex-column justify-content-center align-items-center position-relative ${
                        !product?.status ? styles.comming_soon : " "
                      }`}
                      onClick={() =>
                        product?.status
                          ? history.push(
                              `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}/${product.id}`
                            )
                          : null
                      }
                    >
                      <img
                        src={product.image}
                        alt={product.name}
                        className={`${styles.categotry_icon}`}
                      />
                      <span
                        className={`${styles.categotry_text} text_md font_medium`}
                      >
                        {product.name}
                      </span>
                    </div>
                  )
                })
              : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default connect(null, {
  setBreadcrumbItems,
})(LeadPage)
