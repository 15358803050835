import { BREADCRUM, ROUTES_PATH } from "helpers/constants/constants.helper"
import { useEffect, useState } from "react"
import {
  setBreadcrumbItems,
  addMedia,
  addBimabhaiVideo,
  search,
  addTag,
  getBimabhai,
  setCurrentLanguageCode,
  updateBimabhaiVideo,
  getParentBimaBhaiVideoData,
} from "store/actions"
import { connect } from "react-redux"
import makeAnimated from "react-select/animated"
import * as Yup from "yup"
import { useHistory, useParams } from "react-router-dom"
import FormBimaBhaiVideos from "./FormBimaBhaiVideos"
import { CATEGORY_ENUM } from "helpers/constants/enum.constants.helper"
import { Modal } from "reactstrap"
import { formatBytes } from "helpers/utils.helper"

const parentBreadCrumb = {
  title: BREADCRUM.BIMA_BHAI_VIDEOS,
  pageTitleLink: `/${ROUTES_PATH.BIMA_BHAI_VIDEOS}`,
}

const getBreadCrumb = (parentId, isEditing = false) => {
  if (parentId)
    return [
      {
        title: isEditing
          ? BREADCRUM.EDIT_BIMA_BHAI_VIDEOS
          : BREADCRUM.ADD_BIMA_BHAI_VIDEOS,
        link: `/`,
      },
    ]
  return [
    {
      title: BREADCRUM.ADD_BIMA_BHAI_VIDEOS,
      link: `/`,
    },
  ]
}

const optionGroup2 = []

const optionVideoCategory = [
  { label: "Car", value: CATEGORY_ENUM.CAR },
  { label: "Two Wheeler", value: CATEGORY_ENUM.BIKE },
  { label: "Health", value: CATEGORY_ENUM.HEALTH },
]

const initalBimaBhaiForm = {
  videoTitle: "",
  videoDescription: "",
  thumbnail: [],
  videoFile: [],
  bimaBhaiVideoCategory: "Car",
  tags: [],
}

const BhimaBhaiValidationSchema = Yup.object().shape({
  videoTitle: Yup.string()
    .required("Video title required !")
    .max(30, "Maximun 30 characters only !"),
  videoDescription: Yup.string(),
  thumbnail: Yup.array().min(1, "Thumbnail required !"),
  videoFile: Yup.array().min(1, "Video required !"),
  bimaBhaiVideoCategory: Yup.string().required("Video category required !"),
  tags: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    )
    .test({
      message: "Tags required !",
      test: arr => arr.length >= 1,
    }),
})

const animatedComponents = makeAnimated()

const AddBimaBhaiVideo = ({
  setBreadcrumbItems,
  search,
  addMedia,
  addTag,
  currentLanguageCode,
  addBimabhaiVideo,
  getBimabhai,
  setCurrentLanguageCode,
  updateBimabhaiVideo,
  mediaLoader,
  bimaBhaiLoader,
  getParentBimaBhaiVideoData,
  languages,
}) => {
  const [selectedTags, setselectedTags] = useState(null)
  const [optionTags, setOptionTags] = useState([])
  const [isAddTagenable, setIsAddTagEnable] = useState(false)
  const [addtagModal, setAddTagModal] = useState(false)
  const [addTagValue, setAddTagValue] = useState("")
  const [videoCategory, setVideoCategory] = useState(optionVideoCategory[0])
  const [fromData, setFormData] = useState(initalBimaBhaiForm)
  const [videoMetaData, setVideoMetaData] = useState({})
  const [currentFormState, setCurrentFormState] = useState({})

  const { childId, parentId, childLanguage } = useParams()
  const history = useHistory()

  useEffect(() => {
    if (languages.length) {
      if (childId) {
        setBreadcrumbItems(parentBreadCrumb, getBreadCrumb(parentId, true))
        getBimabhai(childId, handleUpdateChildFormData)
      } else if (parentId && childLanguage) {
        setBreadcrumbItems(parentBreadCrumb, getBreadCrumb(parentId, false))
        getParentBimaBhaiVideoData(parentId, handleAddChildFormData)
      } else setBreadcrumbItems(parentBreadCrumb, getBreadCrumb(null))
    }
  }, [languages])

  function handleMulti3(selectedTags) {
    setselectedTags(selectedTags)
  }

  function uploadFile(file, name, setFieldValue) {
    const formdata = new FormData()
    formdata.append("name", file[0].name)
    formdata.append("file", file[0])
    addMedia({
      formdata,
      cb: res => {
        setFieldValue(name, res)
      },
    })
  }

  const handleTagSearch = value => {
    if (value !== "") {
      search({ searchValue: value + "*", moduleName: "masterTag" }, res => {
        if (res?.data.length) {
          let newTagList = res.data.map(item => {
            return {
              label: item.name,
              value: item.id,
            }
          })
          setOptionTags(newTagList)
        } else {
          setIsAddTagEnable(true)
        }
      })
    }
  }

  function createMediaObj(obj) {
    if (!obj) return []
    return [
      {
        id: obj?.id || "",
        formattedSize: obj?.size ? formatBytes(obj?.size) : "Unknown",
        preview: obj?.location || "",
        name: obj?.originalname || "Unknown",
      },
    ]
  }

  const handleUpdateChildFormData = res => {
    if (res) {
      const {
        description,
        language_code,
        master_bima_bhai_videos: {
          bima_bhai_video_category,
          master_bima_bhai_video_tags,
        },
        title,
        master_media,
        master_media_thumbnail_id,
        master_media_video_id,
      } = res
      let newTags = master_bima_bhai_video_tags
        ? master_bima_bhai_video_tags.map(items => {
            return {
              label: items.list_tag.name,
              value: items.list_tag.id,
            }
          })
        : []
      setVideoMetaData(master_media)
      setCurrentLanguageCode(language_code)
      setFormData({
        videoTitle: title,
        videoDescription: description || "",
        thumbnail: createMediaObj(master_media_thumbnail_id),
        videoFile: createMediaObj(master_media_video_id),
        bimaBhaiVideoCategory: bima_bhai_video_category?.id,
        tags: newTags,
      })
    } else {
      history.push(`/${ROUTES_PATH.BIMA_BHAI_VIDEOS}/${parentId}`)
    }
  }

  const handleAddChildFormData = res => {
    if (res) {
      if (
        !childLanguage ||
        res.bima_bhai_by_languages.find(
          item =>
            item.language_code === childLanguage ||
            (languages.length &&
              !languages.find(
                language => language.language_code === childLanguage
              ))
        )
      )
        history.push(`/${ROUTES_PATH.BIMA_BHAI_VIDEOS}/${parentId}`)

      setCurrentLanguageCode(childLanguage)
      const { bima_bhai_video_category, master_bima_bhai_video_tags } = res

      let master_bima_bhai_video_tags2 = master_bima_bhai_video_tags
        ? master_bima_bhai_video_tags
        : []

      let newTags = master_bima_bhai_video_tags2?.length
        ? master_bima_bhai_video_tags2.map(items => {
            return {
              label: items.list_tag.name,
              value: items.list_tag.id,
            }
          })
        : []
      setFormData({
        ...fromData,
        bimaBhaiVideoCategory: bima_bhai_video_category?.id,
        tags: newTags,
      })
    } else {
      history.push(`/${ROUTES_PATH.BIMA_BHAI_VIDEOS}`)
    }
  }

  const onSubmit = value => {
    // eslint-disable-next-line no-unused-vars
    const { tags, videoDescription, videoTitle, thumbnail, videoFile } = value

    let newTags = tags.map(val => ({
      id: val.value,
      name: val.label,
    }))
    if (childId) {
      updateBimabhaiVideo(
        childId,
        {
          description: videoDescription,
          title: videoTitle,
          video_link: videoFile[0].id,
          thumbnail: thumbnail[0].id,
          tags: newTags,
        },
        () => {
          history.push(`/${ROUTES_PATH.BIMA_BHAI_VIDEOS}`)
        }
      )
    } else if (childLanguage) {
      addBimabhaiVideo(
        {
          ...value,
          videoFile: videoFile[0]?.id,
          thumbnail: thumbnail[0]?.id,
          language: currentLanguageCode,
          tags: newTags,
          masterBimaBhaiVideoId: parentId,
        },
        () => {
          history.push(`/${ROUTES_PATH.BIMA_BHAI_VIDEOS}`)
        }
      )
    } else {
      addBimabhaiVideo(
        {
          ...value,
          videoFile: videoFile[0]?.id,
          thumbnail: thumbnail[0]?.id,
          language: currentLanguageCode,
          tags: newTags,
        },
        () => {
          history.push(`/${ROUTES_PATH.BIMA_BHAI_VIDEOS}`)
        }
      )
    }
  }

  return (
    <div>
      {addtagModal ? (
        <Modal isOpen={addtagModal} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Add Tag</h5>
            <button
              type="button"
              onClick={() => {
                setAddTagModal(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <div className="mb-4">
                <input
                  name="tag"
                  className="form-control"
                  placeholder="eg: Car Insurance"
                  value={addTagValue}
                  onChange={e => {
                    setAddTagValue(e.target.value)
                  }}
                  required
                />
              </div>
              <button
                type="submit"
                className="btn btn-dark w-100"
                disabled={addTagValue.length ? false : true}
                onClick={() => {
                  addTag(addTagValue, res => {
                    setFormData({
                      ...currentFormState,
                      tags: fromData?.tags?.length
                        ? [
                            ...fromData.tags,
                            { label: res?.name, value: res?.id },
                          ]
                        : [{ label: res?.name, value: res?.id }],
                    })
                    setAddTagValue("")
                    setAddTagModal(false)
                  })
                }}
              >
                Add Tag
              </button>
            </form>
          </div>
        </Modal>
      ) : null}
      <FormBimaBhaiVideos
        fromData={fromData}
        BhimaBhaiValidationSchema={BhimaBhaiValidationSchema}
        onSubmit={onSubmit}
        videoCategory={videoCategory}
        setVideoCategory={setVideoCategory}
        optionVideoCategory={optionVideoCategory}
        selectedTags={selectedTags}
        optionGroup2={optionGroup2}
        animatedComponents={animatedComponents}
        childId={childId}
        parentId={parentId}
        handleMulti3={handleMulti3}
        optionTags={optionTags}
        handleTagSearch={handleTagSearch}
        isAddTagenable={isAddTagenable}
        setIsAddTagEnable={setIsAddTagEnable}
        uploadFile={uploadFile}
        setAddTagModal={setAddTagModal}
        videoMetaData={videoMetaData}
        setVideoMetaData={setVideoMetaData}
        mediaLoader={mediaLoader}
        bimaBhaiLoader={bimaBhaiLoader}
        setCurrentFormState={setCurrentFormState}
        addTag={addTag}
        setOptionTags={setOptionTags}
      />
    </div>
  )
}

const mapStateToProps = state => {
  const { Language, Media, BimaBhaiVideos } = state
  const { loading: mediaLoader } = Media
  const { loading: bimaBhaiLoader } = BimaBhaiVideos
  const { currentLanguageCode, languages } = Language
  return { currentLanguageCode, mediaLoader, bimaBhaiLoader, languages }
}

export default connect(mapStateToProps, {
  setBreadcrumbItems,
  addMedia,
  addBimabhaiVideo,
  search,
  addTag,
  getBimabhai,
  setCurrentLanguageCode,
  updateBimabhaiVideo,
  getParentBimaBhaiVideoData,
})(AddBimaBhaiVideo)
