import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Dropdown, DropdownToggle, DropdownMenu, Modal } from "reactstrap"
import { MdPowerSettingsNew } from "react-icons/md";

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { logoutUser } from "store/actions"

// users
import { getUserInfo } from "helpers/utils.helper"

const ProfileMenu = props => {
  const { loading } = props

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [logout_modal, setLogout_Modal] = useState(false)
  const [username, setusername] = useState("AS")
  const user = getUserInfo()

  useEffect(() => {
    if (user?.fullname) {
      setusername(
        `${user?.fullname.split(" ")[0][0].toUpperCase()} ${user?.fullname
          .split(" ")[1][0]
          .toUpperCase()}`
      )
    }
  }, [])

  return (
    <React.Fragment>
      <Modal isOpen={logout_modal} centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Are you sure you want to Logout?</h5>
          <button
            type="button"
            onClick={() => {
              setLogout_Modal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body ">
          <button
            onClick={() => props.logoutUser(props.history)}
            className="btn btn-danger w-md position-relative"
            disabled={loading}
          >
            {loading && (
              <span
                className="spinner-border spinner-border-sm text-light position-absolute"
                style={{ left: "8px", top: "8px" }}
              ></span>
            )}
            Logout
          </button>
          <button
            onClick={() => setLogout_Modal(false)}
            className="btn btn-primary mx-2 w-md"
          >
            Go Back
          </button>
        </div>
      </Modal>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="profile_icon bg-dark d-flex align-items-center justify-content-center">
            {username}
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <div
            className="dropdown-item text-danger cursor-pointer"
            onClick={() => {
              setLogout_Modal(true)
              setMenu(!menu)
            }}
          >
            <MdPowerSettingsNew className="font-size-17 text-muted align-middle me-1" />
            <span>{props.t("Logout")}</span>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  logoutUser: PropTypes.func,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  const { loading } = state.Login
  return { error, success, loading }
}

export default withRouter(
  connect(mapStatetoProps, { logoutUser })(withTranslation()(ProfileMenu))
)
