import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/authentication/UserProfile"

// Authentication related pages
import Login from "../pages/authentication/Login"
import Logout from "../pages/authentication/Logout"
import Register from "../pages/authentication/Register"
import ForgetPwd from "../pages/authentication/ForgetPassword"

// Dashboard
import Dashboard from "pages/dashboard"
import Leads from "pages/leads"
import Faqs from "pages/pages/faqs"
import Pages from "pages/pages"
import Privacy from "pages/pages/Privacy"
import { PAGE_ENUMS, ROUTES_PATH } from "helpers/constants/constants.helper"
import Unauthorized from "pages/authentication/Unauthorized"
import FaqAdd from "pages/pages/faqs/FaqAdd"
import LeadPage from "pages/pages/lead"
import SectionListpage from "pages/pages/lead/SectionListPage"
import BimaBhaiVideos from "pages/bima-bhai-videos"
import AddBimaBhaiVideo from "pages/bima-bhai-videos/bimabhai-form"
import AddSectionPage from "pages/pages/lead/AddSectionPage"

// blog
import Blog from "pages/pages/blog"
import AddBlog from "pages/pages/blog/add-blog"

// Testimonials
import Testimonials from "pages/testimonials"
import TestimonialAdd from "pages/testimonials/TestimonialAdd"
// TODO: Temporary commented for deployment
// import Home from "pages/pages/Home"
// import AboutUs from "pages/pages/AboutUs"
// import StaticPages from "pages/pages/StaticPages"
// import Partners from "pages/pages/partners"
// import ContactUsList from "pages/pages/contact-us"
import GeneralSections from "pages/general-sections"
import SocialMediaLinks from "pages/general-sections/social-media"
import Banner from "pages/banner"
import BannerAdd from "pages/banner/BannerAdd"
import Insurer from "pages/general-sections/insurer"
import InsurerAdd from "pages/general-sections/insurer/InsurerAdd"
// import Email from "pages/general-sections/Email"
// import Offers from "pages/general-sections/Offers"
// import News from "pages/general-sections/News"
// import Settings from "pages/settings"
// import Language from "pages/general-sections/Language"
// import PartnersForm from "pages/pages/partners/PartnersForm"
// import ContactUsForm from "pages/pages/contact-us/ContactUsForm"
// import SocialMediaForm from "pages/general-sections/social-media/SocialMediaForm"

// TODO: removed routes which are not in project scope phase-2
const userRoutes = [
  // this route should be at the end of all other routes

  // TODO: Commented Dashboard temporary
  // { path: "/dashboard", exact: true, component: Dashboard },

  //Leads
  {
    path: `/${ROUTES_PATH.LEADS}`,
    exact: true,
    component: Leads,
    enum: PAGE_ENUMS.LEAD,
  },

  // Pages
  {
    path: `/${ROUTES_PATH.PAGES}`,
    exact: true,
    component: Pages,
    enum: PAGE_ENUMS.PAGES,
  },

  // pages --> privacy policy
  {
    path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.PRIVACY}`,
    exact: true,
    component: Privacy,
    enum: PAGE_ENUMS.PRIVACY,
  },

  // pages --> lead page
  {
    path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}`,
    exact: true,
    component: LeadPage,
    enum: PAGE_ENUMS.LEAD_PAGE,
  },
  {
    path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}/:product`,
    exact: true,
    component: SectionListpage,
    enum: PAGE_ENUMS.LEAD_PAGE_ADD_SECTION,
  },
  {
    path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}/:product/:sectionType`,
    exact: true,
    component: AddSectionPage,
    enum: PAGE_ENUMS.LEAD_PAGE_ADD_SECTION,
  },
  {
    path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}/${ROUTES_PATH.EDIT_LEAD_PAGE}/:product/:sectionType/:childId`,
    exact: true,
    component: AddSectionPage,
    enum: PAGE_ENUMS.LEAD_PAGE_EDIT_SECTION,
  },
  {
    path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}/:product/:sectionType/:leadSectionLanguage/:parentId`,
    exact: true,
    component: AddSectionPage,
    enum: PAGE_ENUMS.LEAD_PAGE_ADD_SECTION,
  },

  // pages --> FAQ page
  {
    path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.FAQS}`,
    exact: true,
    component: Faqs,
    enum: PAGE_ENUMS.FAQS,
  },
  {
    path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.FAQS}/${ROUTES_PATH.ADD_FAQ}`,
    exact: true,
    component: FaqAdd,
    enum: PAGE_ENUMS.ADD_FAQ,
  },
  {
    path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.FAQS}/${ROUTES_PATH.EDIT_FAQ}/:${ROUTES_PATH.CHILD_FAQ_ID}`,
    exact: true,
    component: FaqAdd,
    enum: PAGE_ENUMS.ADD_FAQ,
  },
  {
    path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.FAQS}/${ROUTES_PATH.ADD_FAQ}/:${ROUTES_PATH.FAQ_CATAGORY}/:${ROUTES_PATH.FAQ_LANGUAGE}/:${ROUTES_PATH.PARENT_FAQ_ID}`,
    exact: true,
    component: FaqAdd,
    enum: PAGE_ENUMS.ADD_FAQ,
  },

  // pages --> blog page
  {
    path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.BLOG}`,
    exact: true,
    component: Blog,
    enum: PAGE_ENUMS.BLOGS,
  },
  {
    path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.BLOG}/${ROUTES_PATH.ADD_BLOG}`,
    exact: true,
    component: AddBlog,
    enum: PAGE_ENUMS.BLOGS,
  },
  // pages --> blog page --> add child blog page
  {
    path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.BLOG}/${ROUTES_PATH.ADD_BLOG}/:${ROUTES_PATH.PARENT_BLOG_LANGUAGE}/:${ROUTES_PATH.PARENT_BLOG_ID}`,
    exact: true,
    component: AddBlog,
    enum: PAGE_ENUMS.BLOGS,
  },
  // pages --> blog page --> edit child blog page
  {
    path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.BLOG}/${ROUTES_PATH.EDIT_BLOG}/:${ROUTES_PATH.PARENT_BLOG_ID}/:${ROUTES_PATH.CHILD_BLOG_ID}`,
    exact: true,
    component: AddBlog,
    enum: PAGE_ENUMS.BLOGS,
  },
  // insures
  {
    path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.INSURER}`,
    exact: true,
    component: Insurer,
    enum: PAGE_ENUMS.INSURER,
  },
  {
    path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.INSURER}/${ROUTES_PATH.ADD_INSURER}`,
    exact: true,
    component: InsurerAdd,
    enum: PAGE_ENUMS.ADD_INSURER,
  },
  {
    path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.INSURER}/${ROUTES_PATH.EDIT_INSURER}/:${ROUTES_PATH.INSURER_ID}`,
    exact: true,
    component: InsurerAdd,
    enum: PAGE_ENUMS.EDIT_INSURER,
  },
  // {
  //   path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.HOME}`,
  //   exact: true,
  //   component: Home,
  //   enum: PAGE_ENUMS.HOME,
  // },
  // {
  //   path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.ABOUT_US}`,
  //   exact: true,
  //   component: AboutUs,
  //   enum: PAGE_ENUMS.ABOUT_US,
  // },
  // {
  //   path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.STATIC_PAGES}`,
  //   exact: true,
  //   component: StaticPages,
  //   enum: PAGE_ENUMS.STATIC_PAGES,
  // },

  // // Partners
  // {
  //   path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.PARTNERS}`,
  //   exact: true,
  //   component: Partners,
  //   enum: PAGE_ENUMS.PARTNERS,
  // },
  // {
  //   path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.PARTNERS}/${ROUTES_PATH.ADD_PARTNER}`,
  //   exact: true,
  //   component: PartnersForm,
  //   enum: PAGE_ENUMS.ADD_PARTNER,
  // },
  // {
  //   path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.PARTNERS}/:${ROUTES_PATH.PARTNER_ID}`,
  //   exact: true,
  //   component: PartnersForm,
  //   enum: PAGE_ENUMS.UPDATE_PARTNER,
  // },

  // // Contact Us
  // {
  //   path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.CONTACT_US}`,
  //   exact: true,
  //   component: ContactUsList,
  //   enum: PAGE_ENUMS.CONTACT_US,
  // },
  // {
  //   path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.CONTACT_US}/${ROUTES_PATH.ADD_CONTACT_US}`,
  //   exact: true,
  //   component: ContactUsForm,
  //   enum: PAGE_ENUMS.ADD_CONTACT_US,
  // },
  // {
  //   path: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.CONTACT_US}/:${ROUTES_PATH.CONTACT_US_ID}`,
  //   exact: true,
  //   component: ContactUsForm,
  //   enum: PAGE_ENUMS.UPDATE_CONTACT_US,
  // },

  // Bimabhai video sections
  {
    path: `/${ROUTES_PATH.BIMA_BHAI_VIDEOS}`,
    exact: true,
    component: BimaBhaiVideos,
    enum: PAGE_ENUMS.BIMA_BHAI_VIDEOS,
  },
  {
    path: `/${ROUTES_PATH.BIMA_BHAI_VIDEOS}/${ROUTES_PATH.ADD_BIMA_BHAI_VIDEOS}`,
    exact: true,
    component: AddBimaBhaiVideo,
    enum: PAGE_ENUMS.ADD_BIMA_BHAI_VIDEOS,
  },
  {
    path: `/${ROUTES_PATH.BIMA_BHAI_VIDEOS}/${ROUTES_PATH.EDIT_BIMA_BHAI_VIDEOS}/:parentId/:childId`,
    exact: true,
    component: AddBimaBhaiVideo,
    enum: PAGE_ENUMS.EDIT_BIMA_BHAI_VIDEOS,
  },
  {
    path: `/${ROUTES_PATH.BIMA_BHAI_VIDEOS}/${ROUTES_PATH.ADD_BIMA_BHAI_VIDEOS}/:parentId/:childLanguage`,
    exact: true,
    component: AddBimaBhaiVideo,
    enum: PAGE_ENUMS.ADD_BIMA_BHAI_VIDEOS,
  },

  //profile
  {
    path: `/${ROUTES_PATH.PROFILE}`,
    exact: true,
    component: UserProfile,
    enum: PAGE_ENUMS.USER_PROFILE,
  },

  // Testimonial Routes
  {
    path: `/${ROUTES_PATH.TESTIMONIAL}`,
    exact: true,
    component: Testimonials,
    enum: PAGE_ENUMS.TESTIMONIAL,
  },
  {
    path: `/${ROUTES_PATH.TESTIMONIAL}/${ROUTES_PATH.FEATURED}`,
    exact: true,
    component: Testimonials,
    enum: PAGE_ENUMS.TESTIMONIAL,
  },
  {
    path: `/${ROUTES_PATH.TESTIMONIAL}/${ROUTES_PATH.ADD_TESTIMONIAL}`,
    exact: true,
    component: TestimonialAdd,
    enum: PAGE_ENUMS.ADD_TESTIMONIAL,
  },
  {
    path: `/${ROUTES_PATH.TESTIMONIAL}/${ROUTES_PATH.ADD_TESTIMONIAL}/:childLanguage/:parentId`,
    exact: true,
    component: TestimonialAdd,
    enum: PAGE_ENUMS.ADD_TESTIMONIAL,
  },
  {
    path: `/${ROUTES_PATH.TESTIMONIAL}/${ROUTES_PATH.EDIT_TESTIMONIAL}/:childLanguage/:childId/:parentId`,
    exact: true,
    component: TestimonialAdd,
    enum: PAGE_ENUMS.EDIT_TESTIMONIAL,
  },

  // General Section
  {
    path: `/${ROUTES_PATH.GENERAL_SECTION}`,
    exact: true,
    component: GeneralSections,
    enum: PAGE_ENUMS.GENERAL_SECTIONS,
  },
  {
    path: `/${ROUTES_PATH.GENERAL_SECTION}/${ROUTES_PATH.SOCIAL_MEDIA_LINK}`,
    exact: true,
    component: SocialMediaLinks,
    enum: PAGE_ENUMS.SOCIAL_MEDIA_LINK,
  },
  // {
  //   path: `/${ROUTES_PATH.GENERAL_SECTION}/${ROUTES_PATH.SOCIAL_MEDIA_LINK}/${ROUTES_PATH.ADD_SOCIAL_MEDIA_LINK}`,
  //   exact: true,
  //   component: SocialMediaForm,
  //   enum: PAGE_ENUMS.ADD_SOCIAL_MEDIA_LINK,
  // },
  // {
  //   path: `/${ROUTES_PATH.GENERAL_SECTION}/${ROUTES_PATH.SOCIAL_MEDIA_LINK}/:${ROUTES_PATH.SOCIAL_MEDIA_LINK_ID}`,
  //   exact: true,
  //   component: SocialMediaForm,
  //   enum: PAGE_ENUMS.UPDATE_SOCIAL_MEDIA_LINK,
  // },

  // {
  //   path: `/${ROUTES_PATH.GENERAL_SECTION}/${ROUTES_PATH.EMAIL}`,
  //   exact: true,
  //   component: Email,
  //   enum: PAGE_ENUMS.EMAILS,
  // },
  // {
  //   path: `/${ROUTES_PATH.GENERAL_SECTION}/${ROUTES_PATH.OFFERS}`,
  //   exact: true,
  //   component: Offers,
  //   enum: PAGE_ENUMS.OFFERS,
  // },
  // {
  //   path: `/${ROUTES_PATH.GENERAL_SECTION}/${ROUTES_PATH.NEWS}`,
  //   exact: true,
  //   component: News,
  //   enum: PAGE_ENUMS.NEWS,
  // },
  // {
  //   path: `/${ROUTES_PATH.GENERAL_SECTION}/${ROUTES_PATH.LANGUAGE}`,
  //   exact: true,
  //   component: Language,
  //   enum: PAGE_ENUMS.LANGUAGE,
  // },

  // Settings
  // {
  //   path: `/${ROUTES_PATH.SETTINGS}`,
  //   exact: true,
  //   component: Settings,
  //   enum: PAGE_ENUMS.SETTINGS,
  // },
  // {
  //   path: `/${ROUTES_PATH.SETTINGS}/${ROUTES_PATH.USER_PROFILE}`,
  //   exact: true,
  //   component: UserProfile,
  //   enum: PAGE_ENUMS.USER_PROFILE,
  // },
  // banner
  {
    path: `/${ROUTES_PATH.BANNER}`,
    exact: true,
    component: Banner,
    enum: PAGE_ENUMS.BANNER,
  },
  {
    path: `/${ROUTES_PATH.BANNER}/${ROUTES_PATH.ADD_BANNER}`,
    exact: true,
    component: BannerAdd,
    enum: PAGE_ENUMS.ADD_BANNER,
  },
  {
    path: `/${ROUTES_PATH.BANNER}/${ROUTES_PATH.EDIT_BANNER}/:${ROUTES_PATH.CHILD_BANNER_ID}`,
    exact: true,
    component: BannerAdd,
    enum: PAGE_ENUMS.ADD_FAQ,
  },
  {
    path: `/${ROUTES_PATH.BANNER}/${ROUTES_PATH.ADD_BANNER}/:${ROUTES_PATH.BANNER_LANGUAGE}/:${ROUTES_PATH.PARENT_BANNER_ID}`,
    exact: true,
    component: BannerAdd,
    enum: PAGE_ENUMS.ADD_FAQ,
  },
]

const authRoutes = [
  {
    path: ROUTES_PATH.BASE,
    exact: true,
    component: Dashboard,
  },
  { path: `/${ROUTES_PATH.LOGOUT}`, component: Logout },
  { path: `/${ROUTES_PATH.LOGIN}`, component: Login },
  { path: `/${ROUTES_PATH.FORGOT_PASSWORD}`, component: ForgetPwd },
  { path: `/${ROUTES_PATH.REGISTER}`, component: Register },
  { path: `/${ROUTES_PATH.UNAUTHORIZED}`, component: Unauthorized },
  { path: `/${ROUTES_PATH.NOT_FOUND}`, component: Unauthorized },
  { path: "*", component: () => <Redirect to={`/${ROUTES_PATH.NOT_FOUND}`} /> },
]

export { userRoutes, authRoutes }
