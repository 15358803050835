import FormButton from "components/form-components/FormButton"
import LanguageDropDown from "components/form-components/LanguageDropDown"
import { Field, Form, Formik } from "formik"
import { ROUTES_PATH } from "helpers/constants/constants.helper"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import ReactSelect from "react-select"
import { Card, CardBody, Col, Label, Row } from "reactstrap"
import * as Yup from "yup"
import { LEAD_PAGE } from "helpers/constants/labels.constants"
const textSectionInitialValues = {
  section_title: "",
  section_description: "",
}

const textSectionValidationSchema = Yup.object().shape({
  section_title: Yup.string().required("Required !!!"),
  section_description: Yup.string().required("Required !!!"),
})

const TextSection = ({
  leadPageLoader,
  addTextSection,
  currentLanguageCode,
  getSingleSection,
  setCurrentLanguageCode,
  updateTextSection,
}) => {
  const params = useParams()
  const history = useHistory()
  const [formData, setFormData] = useState(textSectionInitialValues)
  const updateTextSectionForm = response => {
    const { language_code, section_description, section_title } = response
    setCurrentLanguageCode(language_code)
    setFormData({ section_title, section_description })
    setCurrentLanguageCode(language_code)
  }
  const onSubmit = values => {
    if (params.parentId) {
      addTextSection(
        {
          ...values,
          language_code: currentLanguageCode,
          product_type: params.product,
          section_type: `${params.sectionType}`,
          id: params.parentId,
        },
        () => {
          history.push(
            `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}/${params.product}`
          )
        }
      )
    } else if (params.childId) {
      updateTextSection(
        {
          ...values,
          id: params.childId,
        },
        () => {
          history.push(
            `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}/${params.product}`
          )
        }
      )
    } else {
      addTextSection(
        {
          ...values,
          language_code: currentLanguageCode,
          product_type: params.product,
          section_type: `${params.sectionType}`,
        },
        () => {
          history.push(
            `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}/${params.product}`
          )
        }
      )
    }
  }
  useEffect(() => {
    if (params.childId) {
      getSingleSection(params.childId, updateTextSectionForm)
    }
    params.leadSectionLanguage &&
      setCurrentLanguageCode(params.leadSectionLanguage)
  }, [])

  const { DESCRIPTION, LANGUAGE_CODE, SELECTED_SECTION, TITLE } =
    LEAD_PAGE.TEXT_SECTION.TEXT_SECTION_FORM
  return (
    <div>
      <Formik
        initialValues={formData}
        validationSchema={textSectionValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ handleSubmit, errors, touched }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xl="8" sm="12">
                <Card className="p-3">
                  <Row>
                    <div className="mb-4">
                      <Label>{TITLE}</Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="section_title"
                      />
                      {errors.section_title && touched.section_title ? (
                        <div className="position-absolute text-danger">
                          {errors.section_title}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <Label>{DESCRIPTION}</Label>
                      <Field
                        as="textarea"
                        rows="5"
                        className="form-control"
                        name="section_description"
                      />
                      {errors.section_description &&
                      touched.section_description ? (
                        <div className="position-absolute text-danger">
                          {errors.section_description}
                        </div>
                      ) : null}
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <div className="mb-4">
                        <Label>{SELECTED_SECTION}</Label>
                        <ReactSelect
                          value={{
                            label: params?.product,
                            value: params?.product,
                          }}
                          name="Section"
                          classNamePrefix="select2-selection"
                          isDisabled={true}
                        />
                      </div>
                      <div className="mb-4">
                        <Label>{LANGUAGE_CODE}</Label>
                        <LanguageDropDown
                          disabled={
                            params?.childId || params?.parentId ? true : false
                          }
                        />
                      </div>
                    </Row>
                  </CardBody>
                </Card>
                <FormButton
                  customClass="btn-dark"
                  loading={leadPageLoader}
                  type="submit"
                  btnText={
                    params?.childId ? "Update Text Section" : "Add Text Section"
                  }
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default TextSection
