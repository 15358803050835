import FormButton from "components/form-components/FormButton"
import LanguageDropDown from "components/form-components/LanguageDropDown"
import { Field, Form, Formik } from "formik"
import { ROUTES_PATH } from "helpers/constants/constants.helper"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import ReactSelect from "react-select"
import { Card, CardBody, Col, Label, Row } from "reactstrap"
import * as Yup from "yup"
import SelectMediaModal from "../../../components/common/SelectMediaModal"
import { LEAD_PAGE } from "helpers/constants/labels.constants"
import { MdAddCircleOutline } from "react-icons/md"
import { IoCloseSharp } from "react-icons/io5"
const VideoSectionInitialValues = {
  section_title: "",
}

const VideoSectionValidationSchema = Yup.object().shape({
  section_title: Yup.string().required("Required !!!"),
})

const VideoSection = ({
  leadPageLoader,
  currentLanguageCode,
  addVideoSection,
  getSingleSection,
  setCurrentLanguageCode,
  updateVideoSection,
}) => {
  const params = useParams()
  const history = useHistory()
  const [formData, setFormData] = useState(VideoSectionInitialValues)
  const [showMeidaModal, setShowMediaModal] = useState(false)
  const [selectedVideos, setSelectedVideos] = useState([])
  const [errorVideo, setErrorVideo] = useState(false)

  const updateVideoSectionForm = response => {
    const { VideoSections, language_code, section_title } = response

    let newVideoBody
    setCurrentLanguageCode(language_code)
    setFormData({
      section_title,
    })

    if (VideoSections && VideoSections?.length) {
      newVideoBody = VideoSections.filter(item => {
        return item?.bhemaBHaiVideos ? true : false
      }).map(videoObj => {
        return {
          video_title: videoObj?.bhemaBHaiVideos?.title || "",
          bhemaBhaiVideoId: videoObj?.bhemaBHaiVideos?.id || "",
          thumbnail:
            videoObj?.bhemaBHaiVideos?.master_media_thumbnail_id?.location ||
            "",
        }
      })
    }
    setSelectedVideos(newVideoBody)
  }

  const removeVideoCard = id => {
    let filteredArray = selectedVideos.filter(item => {
      return item.bhemaBhaiVideoId != id
    })
    setSelectedVideos(filteredArray)
  }

  const onSubmit = values => {
    let newVideoBody
    if (selectedVideos && selectedVideos.length) {
      newVideoBody = selectedVideos.map(videoObj => {
        return {
          bhemaBhaiVideoId: videoObj.bhemaBhaiVideoId,
          video_title: videoObj.video_title,
        }
      })
      if (params.parentId) {
        addVideoSection(
          {
            ...values,
            video_body: newVideoBody,
            language_code: currentLanguageCode,
            product_type: params.product,
            section_type: `${params.sectionType}`,
            id: params.parentId,
          },
          () => {
            history.push(
              `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}/${params.product}`
            )
          }
        )
      } else if (params.childId) {
        updateVideoSection(
          {
            ...values,
            video_body: newVideoBody,
            id: params.childId,
            language_code: currentLanguageCode,
          },
          () => {
            history.push(
              `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}/${params.product}`
            )
          }
        )
      } else {
        addVideoSection(
          {
            ...values,
            video_body: newVideoBody,
            language_code: currentLanguageCode,
            product_type: params.product,
            section_type: `${params.sectionType}`,
          },
          () => {
            history.push(
              `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.LEAD_PAGE}/${params.product}`
            )
          }
        )
      }
    } else {
      setErrorVideo(true)
    }
  }

  useEffect(() => {
    if (params.childId) {
      getSingleSection(params.childId, updateVideoSectionForm)
    }
    params.leadSectionLanguage &&
      setCurrentLanguageCode(params.leadSectionLanguage)
  }, [])

  useEffect(() => {
    setSelectedVideos([])
  }, [currentLanguageCode])
  const { TITLE, VIDEO_LABEL, ADD_MEDIA, SELECTED_SECTION, LANGUAGE_CODE } =
    LEAD_PAGE.VIDEO_SECTION.VIDEO_SECTION_FORM
  return (
    <div>
      {showMeidaModal && (
        <SelectMediaModal
          multiple={true}
          modalShow={showMeidaModal}
          setModalShow={setShowMediaModal}
          setVideos={setSelectedVideos}
          currentLanguageCode={currentLanguageCode}
          modalHeaderText="Select Media"
        />
      )}
      <Formik
        initialValues={formData}
        validationSchema={VideoSectionValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ handleSubmit, errors, touched }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xl="8" sm="12">
                <Card className="p-3">
                  <Row>
                    <div className="mb-4">
                      <Label>{TITLE}</Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="section_title"
                      />
                      {errors.section_title && touched.section_title ? (
                        <div className="position-absolute text-danger">
                          {errors.section_title}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <Label>{VIDEO_LABEL}</Label>
                      <div className="w-100 d-flex flex-wrap gap-4">
                        {selectedVideos?.length
                          ? selectedVideos.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="video_card_container"
                              >
                                <div
                                  className="video-close"
                                  onClick={() =>
                                    removeVideoCard(item.bhemaBhaiVideoId)
                                  }
                                >
                                  <IoCloseSharp />
                                </div>
                                <div>
                                  <img
                                    src={item.thumbnail}
                                    alt="imagedd"
                                    width="100%"
                                    height="90px"
                                  />
                                </div>
                                <div
                                  className="text-center video-title text-truncate"
                                  title={item.video_title}
                                >
                                  {item.video_title}
                                </div>
                              </div>
                            )
                          })
                          : errorVideo && (
                            <div className="text-danger">Required !!!</div>
                          )}
                        <div
                          className="video_card_container"
                          onClick={() => {
                            setShowMediaModal(true)
                          }}
                        >
                          <div className="attachment-icon">
                            <div className="d-flex flex-column align-items-center justify-content-center gap-2">
                              <MdAddCircleOutline className="attachment-icon-mdicon" />
                              <span>{ADD_MEDIA}</span>
                            </div>
                          </div>
                          {errors.video_body && touched.video_body ? (
                            <div className="position-absolute text-danger">
                              {errors.video_body}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <div className="mb-4">
                        <Label>{SELECTED_SECTION}</Label>
                        <ReactSelect
                          value={{
                            label: params?.product,
                            value: params?.product,
                          }}
                          name="Section"
                          classNamePrefix="select2-selection"
                          isDisabled={true}
                        />
                      </div>
                      <div className="mb-4">
                        <Label>{LANGUAGE_CODE}</Label>
                        <LanguageDropDown
                          disabled={
                            params?.childId || params?.parentId ? true : false
                          }
                        />
                      </div>
                    </Row>
                  </CardBody>
                </Card>
                <FormButton
                  customClass="btn-dark"
                  loading={leadPageLoader}
                  type="submit"
                  btnText={
                    params?.childId
                      ? "Update Video Section"
                      : "Add Video Section"
                  }
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default VideoSection
